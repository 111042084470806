import React, { useState, useEffect } from 'react';

type CrossfadeCarouselProps = {
  images: string[];
  forceActiveImage?: number | null;
  cycle?: boolean;
  interval?: number;
  transition?: number;
  imageStyles?: React.CSSProperties;
  imageProps?: React.HTMLAttributes<HTMLDivElement>;
};

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const BackgroundRotator: React.FC<CrossfadeCarouselProps> = ({
  images,
  forceActiveImage = null,
  cycle = true,
  interval = 5000,
  transition = 500,
  imageStyles = {},
  imageProps = {},
}) => {
  const [active, setActive] = useState(
    forceActiveImage !== null ? forceActiveImage : 0
  );
  const [firstTransitionDone, setFirstTransitionDone] = useState(false);

  useEffect(() => {
    if (forceActiveImage !== null) return;
    if (!cycle) return;

    const timeout = setTimeout(() => {
      const startImageTransition = async () => {
        if (firstTransitionDone) await wait(transition);
        setActive((prevActive) => (prevActive === images.length - 1 ? 0 : prevActive + 1));
        setFirstTransitionDone(true);
      };

      if (cycle) {
        startImageTransition();
      }
    }, interval);

    return () => clearTimeout(timeout);
  }, [active, firstTransitionDone, cycle, forceActiveImage, images.length, interval, transition]);

  useEffect(() => {
    if (forceActiveImage !== null) {
      setActive(forceActiveImage);
    }
  }, [forceActiveImage]);

  return (
    <div className="relative w-full h-full overflow-hidden">
      {images.map((image, index) => (
        <div
          key={`${image}-${index}`}
          className={`absolute inset-0 transition-opacity`}
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: active === index ? 1 : 0,
            transition: `opacity ${transition}ms`,
            ...imageStyles,
          }}
          {...imageProps}
        />
      ))}
    </div>
  );
};
