import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "@telegram-apps/telegram-ui";
import { useBackendQuery } from "@/hooks/useBackendQuery";

export const AwaitingPaymentConfirmation: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const location = useLocation();
  const invoiceId: string = location.state?.invoiceId;

  const { data, isFetching } = useBackendQuery<{ status: "CREATED" | "PAID" }>({
    queryKey: ["newYearLookInvoice", invoiceId],
    url: `/api/v1/new-year-look/invoice/${invoiceId}`,
    refetchInterval: 3000,
  });

  React.useEffect(() => {
    if (isFetching || !data) return;
    const { status } = data;
    if (status === "PAID") {
      queryClient.invalidateQueries({
        queryKey: ["newYearLookState"],
      });
      navigate("/new-year-look", {
        replace: true,
      });
    }
  }, [data, isFetching]);

  return (
    <div
      className="min-h-screen flex flex-col items-center p-4"
      style={{
        paddingBottom: "calc(var(--safe-area-inset-bottom) + 80px)",
      }}
    >
      <Spinner size="l" />
    </div>
  );
};
