import { UseMutationResult, useMutation } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useLaunchParams } from '@telegram-apps/sdk-react';

const BASE_URL = import.meta.env.VITE_API_URL;

type PropTypes<TResponse> = {
  mutationKey: unknown[];
  url: string;
  method: 'POST' | 'PUT' | 'DELETE';
  contentType?: string;
  onSuccess?: (data: TResponse) => void;
  onError?: (error: AxiosError<BackendError>) => void;
};

export interface BackendError {
  correlationId: string;
  message: string;
}

// Generic function definition to pass in TData (input data) and TResponse (expected response) types
export const useBackendMutation = <TData, TResponse>({
  mutationKey,
  url,
  method,
  contentType,
  onSuccess,
  onError,
}: PropTypes<TResponse>): UseMutationResult<TResponse, AxiosError<BackendError>, TData> => {
  const initDataRaw = useLaunchParams().initDataRaw;

  const mutation = useMutation<TResponse, AxiosError<BackendError>, TData>({
    mutationKey,
    mutationFn: async (data: TData) => {
      const response: AxiosResponse<TResponse> = await axios.request({
        method,
        url: `${BASE_URL}${url}`,
        data,
        headers: {
          Authorization: `tma ${initDataRaw}`,
          "Content-Type": contentType || "application/json",
        },
      });

      return response.data;
    },
    onSuccess,
    onError,
  });

  return mutation;
};
