import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Caption,
  Headline,
  Radio,
  Subheadline,
  Text,
} from "@telegram-apps/telegram-ui";
import { useQueryClient } from "@tanstack/react-query";
import { initInvoice, useBackButton } from "@telegram-apps/sdk-react";

import { useBackendMutation } from "@/hooks/useBackendMutation";
import { useScrollToTop } from "@/hooks/useScrollToTop";
import { useTracking } from "@/services/tracking";

import errorHandler from "@/util/errorHandler";

type InvoiceResponse = {
  invoiceLink: string;
  invoiceId: string;
};

export const Payment: React.FC = () => {
  const { track } = useTracking();

  useScrollToTop();

  const backButton = useBackButton();
  const navigate = useNavigate();
  const invoice = initInvoice();
  const queryClient = useQueryClient();

  const invoiceIdRef = React.useRef<string | undefined>(undefined);

  React.useEffect(() => {
    const onBackButtonClick = () => {
      track("Клик на Назад", { group: "new-year-look" });
    };
    backButton.on("click", onBackButtonClick);

    return () => {
      backButton.off("click", onBackButtonClick);
    };
  }, []);

  const generateInvoice = useBackendMutation<
    { product: string },
    InvoiceResponse
  >({
    mutationKey: ["generateNewYearLookInvoice"],
    url: "/api/v1/new-year-look/invoice",
    method: "POST",
    contentType: "application/json",
    onSuccess: (data) => {
      const { invoiceLink, invoiceId } = data;
      invoiceIdRef.current = invoiceId;
      console.log("generateInvoice", { invoiceId });
      invoice.open(invoiceLink, "url").then(onInvoiceLinkClosed);
    },
    onError: errorHandler,
  });

  const createExploreOrder = useBackendMutation<void, InvoiceResponse>({
    mutationKey: ["createExploreNewYearLookOrder"],
    url: "/api/v1/new-year-look/explore",
    method: "POST",
    contentType: "application/json",
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["newYearLookState"] });
      navigate("/new-year-look", {
        replace: true,
      });
    },
  });

  const onInvoiceLinkClosed = (status: unknown) => {
    if (status === "paid") {
      track("Оплатил инвойс", { group: "new-year-look" });
      console.log("onInvoiceLinkClosed", {
        invoiceIdRef: invoiceIdRef.current,
      });
      navigate("/new-year-look/awaiting-payment-confirmation", {
        state: { invoiceId: invoiceIdRef.current },
        replace: true,
      });
    }
  };

  const onBuy = () => {
    track("Клик на Оплатить", {
      group: "new-year-look",
      product: selectedPackage,
    });
    if (selectedPackage === "explore") {
      createExploreOrder.mutate();
    } else {
      generateInvoice.mutate({
        product: selectedPackage,
      });
    }
  };

  const [selectedPackage, setSelectedPackage] =
    React.useState<string>("palette");

  const onChangeSelectionTo = (packageType: string) => {
    setSelectedPackage(packageType);
    track("Выбрал пакет", {
      group: "new-year-look",
      product: packageType,
    });
  };

  return (
    <div className="min-h-screen flex flex-col justify-between gap-6 pt-2 pb-6 px-4">
      <div className="flex flex-col gap-2">
        <Headline weight="2">Выбери план</Headline>
        <Subheadline>
          Каждый сезон появляется более 30.000 новых предметов одежды 🤯
        </Subheadline>
        <Subheadline>
          Найти в этом разнообразии то, что идет тебе, поможет виртуальная
          примерочная.
        </Subheadline>
      </div>

      <div className="flex flex-col w-full gap-4">
        <div
          className="flex flex-row gap-4 p-4 items-center rounded-lg cursor-pointer"
          style={{ border: "1px solid var(--tg-theme-hint-color)" }}
          onClick={() => onChangeSelectionTo("explore")}
        >
          <div>
            <Radio
              checked={selectedPackage === "explore"}
              onChange={() => onChangeSelectionTo("explore")}
              value="explore"
              className="flex flex-col"
            />
          </div>
          <div className="flex-1 flex flex-col gap-2">
            <div className="flex flex-row justify-between items-center gap-2">
              <Headline weight="2">👀 Ознакомительный</Headline>
              <Text>0 ₽</Text>
            </div>
            <Caption>
              Ты увидишь себя в 5 разных образах (без обуви и аксессуаров).
            </Caption>
          </div>
        </div>

        <div
          className="flex flex-row gap-4 p-4 items-center rounded-lg cursor-pointer"
          style={{ border: "1px solid var(--tg-theme-hint-color)" }}
          onClick={() => onChangeSelectionTo("express")}
        >
          <div>
            <Radio
              checked={selectedPackage === "express"}
              onChange={() => onChangeSelectionTo("express")}
              value="express"
              className="flex flex-col"
            />
          </div>
          <div className="flex-1 flex flex-col gap-2">
            <div className="flex-1 flex flex-row justify-between items-center gap-2">
              <Headline weight="2">🚀 Экспресс</Headline>
              <Text>1.750 ₽</Text>
            </div>
            <Caption>
              Корпоратив уже скоро? Проведем для тебя виртуальное дефиле, а 5
              выбранных образов ты &quot;примеришь&quot; и получишь ссылки на вещи.
            </Caption>
          </div>
        </div>

        <div
          className="flex flex-row gap-4 p-4 items-center rounded-lg cursor-pointer"
          style={{ border: "1px solid var(--tg-theme-hint-color)" }}
          onClick={() => onChangeSelectionTo("palette")}
        >
          <div>
            <Radio
              checked={selectedPackage === "palette"}
              onChange={() => onChangeSelectionTo("palette")}
              value="palette"
              className="flex flex-col"
            />
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row justify-between items-center gap-2">
              <Headline weight="2">🎨 Палитра образов</Headline>
              <Text>3.650 ₽</Text>
            </div>
            <Caption>
              Ты увидишь себя в 25 образах.
              <br />
              <br />
              Разные цвета, фасоны, ткани, обувь, аксессуары - за следующие 5
              дней ты найдешь то, что идет тебе!
            </Caption>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-center gap-2">
        <Button
          size="l"
          mode="filled"
          stretched
          onClick={onBuy}
          disabled={!selectedPackage}
          loading={generateInvoice.isPending}
        >
          {selectedPackage === "explore"
            ? `Перейти к загрузке фото`
            : "Перейти к оплате"}
        </Button>
      </div>
    </div>
  );
};
