import { useIntegration } from "@telegram-apps/react-router-integration";
import {
  bindMiniAppCSSVars,
  bindThemeParamsCSSVars,
  bindViewportCSSVars,
  initNavigator,
  useLaunchParams,
  useMiniApp,
  useThemeParams,
  useViewport,
  useClosingBehavior,
  useSwipeBehavior,
} from "@telegram-apps/sdk-react";
import { AppRoot } from "@telegram-apps/telegram-ui";
import React from "react";
import { Navigate, Route, Router, Routes } from "react-router-dom";

import { routes } from "@/navigation/routes.tsx";

export const App: React.FC = () => {
  const lp = useLaunchParams();
  const miniApp = useMiniApp();
  const themeParams = useThemeParams();
  const viewport = useViewport();
  const closingBehavior = useClosingBehavior();
  const swipeBehavior = useSwipeBehavior();

  React.useEffect(() => {
    return bindMiniAppCSSVars(miniApp, themeParams);
  }, [miniApp, themeParams]);

  React.useEffect(() => {
    return bindThemeParamsCSSVars(themeParams);
  }, [themeParams]);

  React.useEffect(() => {
    if (viewport && !viewport.isExpanded) {
      viewport.expand();
    }
    return viewport && bindViewportCSSVars(viewport);
  }, [viewport]);

  React.useEffect(() => {
    // closingBehavior.enableConfirmation();
  }, [closingBehavior]);

  React.useEffect(() => {
    if (swipeBehavior.supports("disableVerticalSwipe")) {
      swipeBehavior.disableVerticalSwipe();
    }
  }, [swipeBehavior]);

  // Create a new application navigator and attach it to the browser history, so it could modify
  // it and listen to its changes.
  const navigator = React.useMemo(() => initNavigator("app-navigation-state"), []);
  const [location, reactNavigator] = useIntegration(navigator);

  // Don't forget to attach the navigator to allow it to control the BackButton state as well
  // as browser history.
  React.useEffect(() => {
    navigator.attach();
    return () => navigator.detach();
  }, [navigator]);

  return (
    <AppRoot
      appearance={miniApp.isDark ? "dark" : "light"}
      platform={["macos", "ios"].includes(lp.platform) ? "ios" : "base"}
    >
      <Router location={location} navigator={reactNavigator}>
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} {...route}>
              {route.nested?.map((nestedRoute) => (
                <Route key={nestedRoute.path} {...nestedRoute} />
              ))}
            </Route>
          ))}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </AppRoot>
  );
};
