import { Area } from "react-easy-crop";

export const getCroppedImg = (imageSrc: string, crop: Area, width: number, height: number) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imageSrc;
    
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = width;
      canvas.height = height;

      if (!ctx) {
        return reject(new Error('Canvas context not found'));
      }

      ctx.clearRect(0, 0, width, height);
      ctx.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        width,
        height
      );

      canvas.toBlob((blob) => {
        if (!blob) return reject(new Error('Canvas is empty'));
        resolve({ url: URL.createObjectURL(blob), blob });
      }, 'image/jpeg');
    };

    image.onerror = reject;
  });
}
