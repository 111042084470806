import { useQuery, UseQueryResult } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useLaunchParams } from "@telegram-apps/sdk-react";

const BASE_URL = import.meta.env.VITE_API_URL;

type PropTypes = {
  queryKey: unknown[];
  url: string;
  retry?: number;
  refetchInterval?: number;
  refetchOnMount?: boolean;
  refetchOnWindowFocus?: boolean;
  gcTime?: number;
  staleTime?: number;
};

type QueryError = AxiosError;

export const useBackendQuery = <QueryResponseData = unknown>({
  queryKey,
  url,
  retry = 1,
  refetchInterval,
  refetchOnMount = false,
  refetchOnWindowFocus = false,
  gcTime,
  staleTime = 0,
}: PropTypes): UseQueryResult<QueryResponseData, QueryError> => {
  const initDataRaw = useLaunchParams().initDataRaw;

  const query = useQuery<QueryResponseData, QueryError>({
    queryKey,
    retry,
    refetchOnMount,
    refetchOnWindowFocus,
    gcTime,
    refetchInterval,
    staleTime,
    queryFn: async () => {
      const response: AxiosResponse<QueryResponseData> = await axios.get(
        `${BASE_URL}${url}`,
        {
          headers: {
            Authorization: `tma ${initDataRaw}`,
          },
        }
      );

      return response.data;
    },
  });

  return {
    ...query
  };
};
