import React from "react";

interface StoredPhotoData {
  dataUrl: string;
  fileName: string;
  type: string;
}

interface PropTypes {
  setSelectedPhotoUrl: (url: string) => void;
  setSelectedPhotoFile: (file: File) => void;
  storageKey: string;
}

export const useLocalPhoto = ({
  setSelectedPhotoUrl,
  setSelectedPhotoFile,
  storageKey,
}: PropTypes) => {

  React.useEffect(() => {
    const storedPhotoDataString = localStorage.getItem(storageKey);
    if (storedPhotoDataString) {
      try {
        const storedPhotoData: StoredPhotoData = JSON.parse(
          storedPhotoDataString
        );
        setSelectedPhotoUrl(storedPhotoData.dataUrl);
        fetch(storedPhotoData.dataUrl)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], storedPhotoData.fileName, {
              type: storedPhotoData.type,
            });
            setSelectedPhotoFile(file);
          });
      } catch (error) {
        console.log("Error parsing storedPhotoData", error);
        localStorage.removeItem(storageKey);
      }
    }
  }, []);

  const handlePhotoSelected = (photo: File, onDone: () => void) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target && typeof e.target.result === "string") {
        const photoData: StoredPhotoData = {
          dataUrl: e.target.result,
          fileName: photo.name,
          type: photo.type,
        };
        setSelectedPhotoUrl(photoData.dataUrl);
        setSelectedPhotoFile(photo);
        localStorage.setItem(storageKey, JSON.stringify(photoData));
      }
    };
    reader.readAsDataURL(photo);
    onDone();
  };

  return {
    handlePhotoSelected,
  };
};
