import React from "react";

export const useScrollToTop = () => {
  React.useEffect(() => {
    const mobileWrap = window.document.querySelector(".mobile-wrap");
    if (mobileWrap) {
      mobileWrap.scrollTop = 0;
    }
  }, []);
};
