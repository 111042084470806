import React from "react";
import { Spinner } from "@telegram-apps/telegram-ui";

export const LoadingComponent: React.FC = () => {
  return (
    <div
      className="min-h-screen flex flex-col items-center p-4"
      style={{
        paddingBottom: "calc(var(--safe-area-inset-bottom) + 80px)",
      }}
    >
      <Spinner size="l" />
    </div>
  );
};
