import { SDKProvider } from "@telegram-apps/sdk-react";
import { type FC, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { App } from "@/components/App.tsx";
import { ErrorBoundary } from "@/components/ErrorBoundary.tsx";

import { ModalContextProvider } from "@/contexts/ModalContextProvider";
import { AnalyticsProvider } from "@/components/AnalyticsProvider";

const { VITE_ENV } = import.meta.env;

const ErrorBoundaryError: FC<{ error: unknown }> = ({ error }) => (
  <div>
    <p>An unhandled error occurred:</p>
    <blockquote>
      <code>
        {error instanceof Error
          ? error.message
          : typeof error === "string"
          ? error
          : JSON.stringify(error)}
      </code>
    </blockquote>
  </div>
);

const queryClient = new QueryClient();

const Inner: FC = () => {
  const debug = VITE_ENV === "development";

  useEffect(() => {
    if (debug) {
      import("eruda").then((lib) => lib.default.init());
    }
  }, [debug]);

  return (
    <QueryClientProvider client={queryClient}>
      <SDKProvider acceptCustomStyles debug={debug}>
        <AnalyticsProvider>
          <ModalContextProvider>
            <App />
          </ModalContextProvider>
        </AnalyticsProvider>
      </SDKProvider>
    </QueryClientProvider>
  );
};

export const Root: FC = () => (
  <ErrorBoundary fallback={ErrorBoundaryError}>
    <Inner />
  </ErrorBoundary>
);
