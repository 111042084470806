import React from "react";
import { Button, Text, Title } from "@telegram-apps/telegram-ui";

import pinchToZoomGif from "@/assets/zoom.gif";

interface PropTypes {
  onCloseModal: () => void;
}

export const PhotoEditingTipModal: React.FC<PropTypes> = ({ onCloseModal }) => {

  return (
    <div className="p-4">
      <div className="flex flex-col gap-2">
        <Title className="mb-2">Как обрезать фотографию?</Title>
        <div className="flex flex-col gap-4">
          <Text>
            Используйте жест pinch-to-zoom, чтобы обрезать фотографию.
          </Text>
          <div className="w-full flex flex-col justify-around items-center h-[200px]">
            <img className="w-16 h-16" src={pinchToZoomGif} alt="pinch to zoom" />
          </div>
          <Button mode="plain" onClick={onCloseModal}>
            Понятно
          </Button>
        </div>
      </div>
    </div>
  );
};
