import type { ComponentType, JSX } from "react";

import { IndexPage } from "@/pages/IndexPage/IndexPage";
import { InitDataPage } from "@/pages/TechPages/InitDataPage/InitDataPage";
import { LaunchParamsPage } from "@/pages/TechPages/LaunchParamsPage/LaunchParamsPage";
import { ThemeParamsPage } from "@/pages/TechPages/ThemeParamsPage/ThemeParamsPage";
import { Admin } from "@/pages/TechPages/Admin";

import { NewYearLook, newYearLookRoutes } from "@/pages/NewYearLook/NewYearLook";

interface Route {
  path: string;
  Component: ComponentType;
  title?: string;
  icon?: JSX.Element;
  nested?: Route[];
}

export const routes: Route[] = [
  { path: "/", Component: IndexPage },
  {
    path: "/new-year-look/*",
    Component: NewYearLook,
    title: "New Year Look",
    nested: newYearLookRoutes,
  },
  { path: "/init-data", Component: InitDataPage, title: "Init Data" },
  { path: "/theme-params", Component: ThemeParamsPage, title: "Theme Params" },
  {
    path: "/launch-params",
    Component: LaunchParamsPage,
    title: "Launch Params",
  },
  { path: "/admin", Component: Admin, title: "Admin" },
];
