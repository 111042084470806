import React from "react";
import { useNavigate } from "react-router-dom";

import { useCurrentUser } from "@/hooks/useCurrentUser";

export const IndexPage: React.FC = () => {
  const navigate = useNavigate();
  const { id: userId } = useCurrentUser();

  React.useEffect(() => {
    if (!userId) {
      return;
    }

    const timer = setTimeout(() => {
      if (userId === "1146034") {
        navigate("/admin", { replace: true });
      } else {
        navigate("/new-year-look", { replace: true });
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [navigate, userId]);

  return null;
};
