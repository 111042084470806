import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";

import { Root } from '@/components/Root';

// Uncomment this import in case, you would like to develop the application even outside
// the Telegram application, just in your browser.
import './mockEnv.ts';

import '@telegram-apps/telegram-ui/dist/styles.css';
import './index.css';

declare module '@telegram-apps/telegram-ui' {
  interface ChipProps {
    Component?: React.ElementType;
  }
}

const { VITE_SENTRY_DSN_TMA } = import.meta.env;

Sentry.init({
  dsn: VITE_SENTRY_DSN_TMA,
  integrations: [],
});

ReactDOM.createRoot(document.getElementById('content')!).render(<Root/>);
