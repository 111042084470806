import React from "react";
import {
  TwaAnalyticsProvider,
  type TelegramWebAppData,
} from "@tonsolutions/telemetree-react";
import { useInitData, useLaunchParams } from "@telegram-apps/sdk-react";

const {
  VITE_TELEMETREE_PROJECT_ID,
  VITE_TELEMETREE_APP_ID,
  VITE_TELEMETREE_APP_NAME,
} = import.meta.env;

export const AnalyticsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const initData = useInitData();
  const launchParams = useLaunchParams();

  if (!initData || !launchParams) {
    return null;
  }

  const telegramWebAppData: TelegramWebAppData = {
    query_id: initData?.queryId,
    user: initData?.user,
    chat_type: initData?.chatType,
    chat_instance: initData?.chatInstance,
    start_param: initData?.startParam,
    auth_date: initData?.authDate,
    hash: initData?.hash,
    platform: launchParams?.platform,
  };

  return (
    <TwaAnalyticsProvider
      projectId={VITE_TELEMETREE_PROJECT_ID}
      apiKey={VITE_TELEMETREE_APP_ID}
      appName={VITE_TELEMETREE_APP_NAME}
      telegramWebAppData={telegramWebAppData}
    >
      {children}
    </TwaAnalyticsProvider>
  );
};
