import { useMemo } from "react";
import { useInitData, type User } from "@telegram-apps/sdk-react";

import { type DisplayDataRow } from "@/components/DisplayData/DisplayData.tsx";

function getUserRows(user: User): DisplayDataRow[] {
  return [
    { title: "id", value: user.id.toString() },
    { title: "username", value: user.username },
    { title: "photoUrl", value: user.photoUrl },
    { title: "lastName", value: user.lastName },
    { title: "firstName", value: user.firstName },
    { title: "isBot", value: user.isBot },
    { title: "isPremium", value: user.isPremium },
    { title: "languageCode", value: user.languageCode },
    { title: "allowsWriteToPm", value: user.allowsWriteToPm },
    { title: "addedToAttachmentMenu", value: user.addedToAttachmentMenu },
  ];
}

function rowsToObject(rows: DisplayDataRow[]): Record<string, string> {
  return rows.reduce((acc, { title, value }) => {
    acc[title] = value as string;
    return acc;
  }, {} as Record<string, string>);
}

export const useCurrentUser = () => {
  const initData = useInitData();

  const userRows = useMemo<DisplayDataRow[] | undefined>(() => {
    return initData && initData.user ? getUserRows(initData.user) : undefined;
  }, [initData]);

  const receiverRows = useMemo<DisplayDataRow[] | undefined>(() => {
    return initData && initData.receiver
      ? getUserRows(initData.receiver)
      : undefined;
  }, [initData]);

  const mappedUser = useMemo(() => {
    return {
      ...(userRows ? rowsToObject(userRows) : {}),
      ...(receiverRows ? rowsToObject(receiverRows) : {}),
    };
  }, [userRows, receiverRows]);

  return mappedUser;
};
