import React, { useEffect, useState } from "react";
import { differenceInMilliseconds } from "date-fns";

interface CountdownProps {
  deadline: Date;
  className?: string;
}

const Countdown: React.FC<CountdownProps> = ({ deadline, className }) => {
  const [timeLeft, setTimeLeft] = useState<string>("00:00");

  const updateCountdown = () => {
    const now = new Date();
    const diff = differenceInMilliseconds(deadline, now);

    if (diff <= 0) {
      setTimeLeft("00:00");
      return;
    }

    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    setTimeLeft(formattedTime);
  };

  useEffect(() => {
    updateCountdown(); // Initial calculation
    const intervalId = setInterval(updateCountdown, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [deadline]);

  return <div className={className}>{timeLeft}</div>;
};

export default Countdown;
