import React from "react";

export type ModalLibrary = {
  [modalKey: string]: React.ReactNode;
};

export const useModal = () => {
  const [modalLibrary, setModalLibrary] = React.useState<ModalLibrary>({});
    React.useState<ModalLibrary>({});
  const [modalType, setModalType] = React.useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const addToModalLibrary = (modals: ModalLibrary) => {
    setModalLibrary((prev) => ({ ...(prev || {}), ...modals }));
  };

  const renderModal = () => {
    if (!modalType) return null;
    return modalLibrary[modalType];
  };

  return {
    addToModalLibrary,
    renderModal,
    isModalOpen,
    setIsModalOpen,
    setModalType,
  };
};
