import React from "react";
import { useDropzone } from "react-dropzone";
import { Caption, Text, Title, Button } from "@telegram-apps/telegram-ui";
import { Icon20UploadOutline } from "@vkontakte/icons";

import example from "../../../assets/example.png";

interface PropTypes {
  onDrop: (acceptedFiles: File[]) => void;
}

export const Dropzone: React.FC<PropTypes> = ({ onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
    multiple: false,
  });

  return (
    <div className="p-4">
      <div className="flex flex-col gap-2">
        <Title className="mb-4">Загрузи свою фотографию</Title>
        <Text>Лучший результат ты получишь, если загрузишь фотографию:</Text>
        <ul className="list-disc pl-5">
          <li>
            <Text>В полный рост</Text>
          </li>
          <li>
            <Text>В анфас</Text>
          </li>
          <li>
            <Text>На однотонном фоне</Text>
          </li>
          <li>
            <Text>В облегающей одежде</Text>
          </li>
        </ul>
      </div>

      <div className="mt-8">
        <div className="flex flex-row gap-1">
          <img src={example} alt="Photo" className="flex-1 h-48 object-contain" />
        </div>
        <div>
          <Caption>Пример подходящей фотографии</Caption>
        </div>
      </div>

      <div
        {...getRootProps()}
        className="mt-8 px-4 py-8 border-2 border-dashed rounded-md"
        style={{ borderColor: "var(--tg-theme-hint-color)" }}
      >
        <input {...getInputProps()} />
        <div className="flex flex-col items-center justify-center">
          {!isDragActive ? (
            <Button before={<Icon20UploadOutline />} mode="filled" size="s">
              Загрузить фотографию
            </Button>
          ) : (
            <Text>Перетащи фотографию сюда</Text>
          )}
        </div>
      </div>
    </div>
  );
};
