import React from "react";

import { useModal, ModalLibrary } from "@/hooks/useModal";

export const ModalContext = React.createContext<{
  addToModalLibrary: (modals: ModalLibrary) => void;
  setModalType: (modalType: string) => void;
  renderModal: () => React.ReactNode;
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
}>({
  renderModal: () => null,
  isModalOpen: false,
  setIsModalOpen: () => {},
  addToModalLibrary: () => {},
  setModalType: () => {},
});

interface PropTypes {
  children: React.ReactNode;
}

export const ModalContextProvider: React.FC<PropTypes> = ({ children }) => {
  const {
    addToModalLibrary,
    setModalType,
    renderModal,
    isModalOpen,
    setIsModalOpen,
  } = useModal();

  return (
    <ModalContext.Provider
      value={{
        addToModalLibrary,
        setModalType,
        renderModal,
        isModalOpen,
        setIsModalOpen,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
