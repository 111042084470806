import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Headline, Subheadline } from "@telegram-apps/telegram-ui";
import { LoadingComponent } from "@/components/LoadingComponent";
import { useBackendQuery } from "@/hooks/useBackendQuery";
import { useBackendMutation } from "@/hooks/useBackendMutation";
import { useQueryClient } from "@tanstack/react-query";

import errorHandler from "@/util/errorHandler";

export type Outfit = {
  id: number;
  name: string;
  image: string;
};

export const OutfitSelection: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: outfits, isLoading } = useBackendQuery<Outfit[]>({
    queryKey: ["outfits"],
    url: "/api/v1/new-year-look/outfits",
  });

  const saveSelection = useBackendMutation({
    mutationKey: ["save-selection"],
    url: "/api/v1/new-year-look/outfits",
    method: "POST",
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["newYearLookState"] });
      navigate("/new-year-look");
    },
    onError: errorHandler,
  });

  const [selectedOutfits, setSelectedOutfits] = React.useState<number[]>([]);

  const handleOutfitClick = (outfitId: number) => {
    if (selectedOutfits.includes(outfitId)) {
      setSelectedOutfits((prev) => prev.filter((id) => id !== outfitId));
    } else {
      if (selectedOutfits.length >= 5) {
        alert("Вы можете выбрать не более 5 образов");
        return;
      }
      setSelectedOutfits((prev) => [...prev, outfitId]);
    }
  };

  const onCtaClick = () => {
    if (selectedOutfits.length < 5) {
      alert("Выберите 5 образов");
      return;
    }
    saveSelection.mutate({
      outfits: selectedOutfits,
    });
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <div className="min-h-screen w-full flex flex-col justify-between gap-4 pt-2 pb-6 px-4">
      <div className="flex flex-col gap-2">
        <Headline weight="2">Выбери образы</Headline>
        <Subheadline>
          Ты получишь свою фотографию в 5 понравившихся образах.
        </Subheadline>
      </div>
      <div className="flex-1 grid grid-cols-2 gap-4">
        {outfits &&
          outfits.map((outfit) => (
            <div
              key={outfit.id}
              onClick={(e) => {
                e.stopPropagation();
                handleOutfitClick(outfit.id);
              }}
              className="cursor-pointer rounded-lg p-2"
              style={{
                border: selectedOutfits.includes(outfit.id)
                  ? "2px solid var(--tg-theme-hint-color)"
                  : "2px solid transparent",
              }}
            >
              <img
                src={outfit.image}
                alt={outfit.name}
                className="w-full h-64 object-cover"
              />
            </div>
          ))}
      </div>
      <div className="w-full pb-4 pt-8">
        <Button size="l" mode="filled" stretched onClick={onCtaClick}>
          Сохранить выбор
        </Button>
      </div>
    </div>
  );
};
