import React from "react";
import { useScrollToTop } from "@/hooks/useScrollToTop";
import { useUtils, useBackButton } from "@telegram-apps/sdk-react";
import { Headline, Subheadline, Button } from "@telegram-apps/telegram-ui";

import { STYLIST_TELEGRAM_LINK } from "@/util/constants";
import { useBackendQuery } from "@/hooks/useBackendQuery";
import { NewYearLookState } from "@/types";
import { useNavigate } from "react-router-dom";
import Countdown from "../components/Countdown";

const headlines: Record<string, string> = {
  express: "Экспресс",
  explore: "Ознакомительный план",
  palette: "Палитра образов",
};

const descriptions: Record<string, string> = {
  explore:
    "Фотографию в 5 разных образах ты получишь в чат.",
  express:
    "Фотографию в 5 выбранных тобой образах ты получишь в чат.",
  palette:
    "Следующие 5 дней тебе в чат будут приходить по 5 фотографий в новых образах.",
};

export const Success: React.FC = () => {
  const backButton = useBackButton();
  const utils = useUtils();
  const navigate = useNavigate();

  useScrollToTop();

  React.useEffect(() => {
    backButton.hide();
  }, []);

  const { data } = useBackendQuery<NewYearLookState>({
    queryKey: ["newYearLookState"],
    url: "/api/v1/new-year-look",
  });

  const { order } = data || {};
  const { serviceLevel, upsellOptions, deadline } = order || {};

  const hasUpsell = upsellOptions && upsellOptions.length > 0;

  const openChat = () => {
    utils.openTelegramLink(STYLIST_TELEGRAM_LINK);
  };

  const goToUpsell = () => {
    navigate("/new-year-look/upsell");
  };

  if (!serviceLevel) {
    return null;
  }

  return (
    <div className="min-h-screen w-full flex flex-col justify-between gap-4 pt-2 pb-6 px-4">
      <div className="flex flex-col gap-2">
        <Headline weight="2">Успех!</Headline>
        <Subheadline>
          ИИ приступил к обработке твоей фотографии по плану{" "}
          &quot;{headlines[serviceLevel]}&quot;.
        </Subheadline>
        <Subheadline>{descriptions[serviceLevel]}</Subheadline>
      </div>
      <div className="flex-1">
        <Subheadline>Время до получения результата:</Subheadline>
        <Countdown deadline={deadline!} className="text-2xl text-center w-full mt-10" />
      </div>
      <div className="w-full flex flex-col justify-between gap-4">
        {hasUpsell && (
          <Subheadline>
            Сменить план, чтобы получить больше образов, ты можешь по{" "}
            <span
              className="cursor-pointer"
              style={{
                color: "var(--tg-theme-link-color)",
              }}
              onClick={goToUpsell}
            >
              ссылке
            </span>
            .
          </Subheadline>
        )}
        <Subheadline>
          Хочешь глубокую трансформацию своего стиля? Расскажи о своих
          пожеланиях стилисту в чате!
        </Subheadline>
        <Button size="l" mode="plain" stretched onClick={() => openChat()}>
          Перейти в чат со стилистом
        </Button>
      </div>
    </div>
  );
};
