import React from "react";
import { Headline, Subheadline, Button } from "@telegram-apps/telegram-ui";
import { useNavigate } from "react-router-dom";

import { useTracking } from "@/services/tracking";
import { useScrollToTop } from "@/hooks/useScrollToTop";

import { BackgroundRotator } from "../components/BackgroundRotator";

import bg1Image from "../assets/bg1.png";
import bg2Image from "../assets/bg2.png";
import bg3Image from "../assets/bg3.png";
import bg4Image from "../assets/bg4.png";
import bg5Image from "../assets/bg5.png";

export const Landing: React.FC = () => {
  const { track } = useTracking();
  const navigate = useNavigate();

  useScrollToTop();

  const onCtaClick = () => {
    track("", { group: "new-year-look" });
    navigate("/new-year-look/path");
  };

  return (
    <div className="h-screen w-full relative">
      <div className="absolute inset-0 bg-cover bg-center bg-[#3B2815]">
        <BackgroundRotator
          images={[bg1Image, bg2Image, bg3Image, bg4Image, bg5Image]}
          interval={1000}
        />
      </div>
      <div className="relative z-10 h-full flex flex-col justify-end items-center">
        <div className="w-full flex flex-col bg-black bg-opacity-40 backdrop-blur-md py-6 px-4">
          <>
            <Headline weight="2" className="text-white">
              Еще не решила что надеть на новогоднюю вечеринку?
            </Headline>
            <Subheadline className="mt-2 text-white">
              В виртуальной примерочной ты подберешь идеальный образ и получишь
              ссылки на магазины.
            </Subheadline>
          </>
          <div className="w-full flex flex-row justify-between gap-4 mt-8">
            <>
              <Button
                size="l"
                mode="filled"
                stretched
                onClick={() => onCtaClick()}
              >
                Начать
              </Button>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};
