import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Caption,
  Headline,
  Radio,
  Subheadline,
  Text,
} from "@telegram-apps/telegram-ui";
import { initInvoice, useBackButton } from "@telegram-apps/sdk-react";

import { useBackendMutation } from "@/hooks/useBackendMutation";
import { useBackendQuery } from "@/hooks/useBackendQuery";
import { useScrollToTop } from "@/hooks/useScrollToTop";
import { useTracking } from "@/services/tracking";

import errorHandler from "@/util/errorHandler";
import { LoadingComponent } from "@/components/LoadingComponent";
import { NewYearLookState, Products } from "@/types";

type InvoiceResponse = {
  invoiceLink: string;
  invoiceId: string;
};

export const Upsell: React.FC = () => {
  const { track } = useTracking();

  useScrollToTop();

  const backButton = useBackButton();
  const navigate = useNavigate();
  const invoice = initInvoice();

  const [previousInvoiceId, setPreviousInvoiceId] = React.useState<
    string | undefined
  >(undefined);

  const invoiceIdRef = React.useRef<string | undefined>(undefined);

  const { data, isFetching } = useBackendQuery<NewYearLookState>({
    queryKey: ["newYearLookState"],
    url: "/api/v1/new-year-look/state",
  });

  const { order } = data || {};
  const { upsellOptions } = order || {};
  console.log(upsellOptions);

  React.useEffect(() => {
    const onBackButtonClick = () => {
      track("Клик на Назад", { group: "new-year-look" });
    };
    backButton.on("click", onBackButtonClick);

    return () => {
      backButton.off("click", onBackButtonClick);
    };
  }, []);

  const generateInvoice = useBackendMutation<
    { product: Products; previousInvoiceId: string | undefined },
    InvoiceResponse
  >({
    mutationKey: ["generateNewYearLookInvoice"],
    url: "/api/v1/new-year-look/invoice",
    method: "POST",
    contentType: "application/json",
    onSuccess: (data) => {
      const { invoiceLink, invoiceId } = data;
      invoiceIdRef.current = invoiceId;
      invoice.open(invoiceLink, "url").then(onInvoiceLinkClosed);
    },
    onError: errorHandler,
  });

  const onInvoiceLinkClosed = (status: unknown) => {
    if (status === "paid") {
      track("Оплатил инвойс", { group: "new-year-look" });
      navigate("/new-year-look/awaiting-payment-confirmation", {
        replace: true,
        state: { invoiceId: invoiceIdRef.current! },
      });
    }
  };

  const onBuy = () => {
    if (!selectedPackage) return;
    track("Клик на Повысить тариф", {
      group: "new-year-look",
      product: selectedPackage,
    });
    generateInvoice.mutate({
      product: selectedPackage,
      previousInvoiceId,
    });
  };

  const [selectedPackage, setSelectedPackage] = React.useState<Products>();

  const onChangeSelectionTo = (
    packageType: Products,
    previousInvoiceId?: string
  ) => {
    setSelectedPackage(packageType);
    setPreviousInvoiceId(previousInvoiceId);
    track("Выбрал пакет", {
      group: "new-year-look",
      product: packageType,
    });
  };

  React.useEffect(() => {
    if (isFetching) return;
    if (!upsellOptions || upsellOptions.length === 0) {
      navigate("/new-year-look", {
        replace: true,
      });
    }
  }, [upsellOptions, isFetching]);

  const express =
    upsellOptions &&
    upsellOptions.find((option) => option.product === "express");
  const palette =
    upsellOptions &&
    upsellOptions.find((option) => option.product === "palette");
  const expressToPaletteUpgrade =
    upsellOptions &&
    upsellOptions.find(
      (option) => option.product === "expressToPaletteUpgrade"
    );

  React.useEffect(() => {
    if (isFetching) return;

    if (expressToPaletteUpgrade) {
      setSelectedPackage("expressToPaletteUpgrade");
    } else if (palette) {
      setSelectedPackage("palette");
    }
  }, [upsellOptions, isFetching]);

  if (isFetching || !upsellOptions) {
    return <LoadingComponent />;
  }

  return (
    <div className="min-h-screen flex flex-col justify-between gap-6 pt-2 pb-6 px-4">
      <div className="flex flex-col gap-2">
        <Headline weight="2">Выбери план</Headline>
        <Subheadline>
          Каждый сезон появляется более 30.000 новых предметов одежды 🤯
        </Subheadline>
        <Subheadline>
          Найти в этом разнообразии то, что идет тебе, поможет виртуальная
          примерочная.
        </Subheadline>
      </div>

      <div className="flex flex-col w-full gap-4">
        {express && (
          <div
            className="flex flex-row gap-4 p-4 items-center rounded-lg cursor-pointer"
            style={{ border: "1px solid var(--tg-theme-hint-color)" }}
            onClick={() => onChangeSelectionTo("express")}
          >
            <div>
              <Radio
                checked={selectedPackage === "express"}
                onChange={() => onChangeSelectionTo("express")}
                value="express"
                className="flex flex-col"
              />
            </div>
            <div className="flex-1 flex flex-col gap-2">
              <div className="flex-1 flex flex-row justify-between items-center gap-2">
                <Headline weight="2">🚀 Экспресс</Headline>
                <Text>{express.priceDifferenceDisplay} ₽</Text>
              </div>
              <Caption>
                Корпоратив уже скоро? Проведем для тебя виртуальное дефиле, а 5
                выбранных образов ты &quot;примеришь&quot; и получишь ссылки на
                вещи.
              </Caption>
            </div>
          </div>
        )}

        {palette && (
          <div
            className="flex flex-row gap-4 p-4 items-center rounded-lg cursor-pointer"
            style={{ border: "1px solid var(--tg-theme-hint-color)" }}
            onClick={() => onChangeSelectionTo("palette")}
          >
            <div>
              <Radio
                checked={selectedPackage === "palette"}
                onChange={() => onChangeSelectionTo("palette")}
                value="palette"
                className="flex flex-col"
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row justify-between items-center gap-2">
                <Headline weight="2">🎨 Палитра образо</Headline>
                <Text>{palette.priceDifferenceDisplay} ₽</Text>
              </div>
              <Caption>
                Ты увидишь себя в 25 образах.
                <br />
                <br />
                Разные цвета, фасоны, ткани, обувь, аксессуары - за следующие 5
                дней ты найдешь то, что идет тебе!
              </Caption>
            </div>
          </div>
        )}

        {expressToPaletteUpgrade && (
          <div
            className="flex flex-row gap-4 p-4 items-center rounded-lg cursor-pointer"
            style={{ border: "1px solid var(--tg-theme-hint-color)" }}
            onClick={() =>
              onChangeSelectionTo(
                "expressToPaletteUpgrade",
                expressToPaletteUpgrade.previousInvoiceId
              )
            }
          >
            <div>
              <Radio
                checked={selectedPackage === "expressToPaletteUpgrade"}
                onChange={() =>
                  onChangeSelectionTo(
                    "expressToPaletteUpgrade",
                    expressToPaletteUpgrade.previousInvoiceId
                  )
                }
                value="expressToPaletteUpgrade"
                className="flex flex-col"
              />
            </div>
            <div className="flex-1 flex flex-col gap-2">
              <div className="flex-1 flex flex-row justify-between items-center gap-2">
                <Headline weight="2">🎨 Палитра образов</Headline>
                <Text>
                  + {expressToPaletteUpgrade.priceDifferenceDisplay} ₽
                </Text>
              </div>
              <Caption>
                Ты увидишь себя в 25 образах.
                <br />
                <br />
                Разные цвета, фасоны, ткани, обувь, аксессуары - за следующие 5
                дней ты найдешь то, что идет тебе!
              </Caption>
            </div>
          </div>
        )}
      </div>

      <div className="w-full flex flex-col items-center gap-2">
        {expressToPaletteUpgrade && (
          <Subheadline>
            Ты оплачиваешь только разницу в цене между планом Экспресс и
            Палитрой образов.
          </Subheadline>
        )}
        <Button
          size="l"
          mode="filled"
          stretched
          onClick={onBuy}
          disabled={!selectedPackage}
          loading={generateInvoice.isPending}
        >
          {expressToPaletteUpgrade ? "Оплатить разницу" : "Оплатить"}
        </Button>
      </div>
    </div>
  );
};
