import React from "react";
import {
  Headline,
  Button,
  Cell,
  Subheadline,
} from "@telegram-apps/telegram-ui";
import { useNavigate } from "react-router-dom";

import { useTracking } from "@/services/tracking";
import { useScrollToTop } from "@/hooks/useScrollToTop";

export const Path: React.FC = () => {
  const { track } = useTracking();
  const navigate = useNavigate();

  useScrollToTop();

  const onCtaClick = () => {
    track("", { group: "new-year-look" });
    navigate("/new-year-look/how-it-works");
  };

  return (
    <div className="min-h-screen w-full flex flex-col justify-between gap-4 pt-2 pb-6 px-4">
      <div className="flex flex-col gap-2">
        <Headline weight="2">Путь к идеальному образу</Headline>
        <Subheadline>
          От тебя потребуется сделать и загрузить фотографию в полный рост.

          <br />
          <br />
          Далее ИИ примерит на тебя:
        </Subheadline>
      </div>
      <div className="flex-1 flex flex-col">
        <Cell
          before={"🎨"}
          description="Гармонирующие с твоим тоном кожи и чертами лица"
          multiline
        >
          Цвета
        </Cell>
        <Cell
          before={"👗"}
          description="Идеальный для твоей фигуры"
          multiline
        >
          Фасон
        </Cell>
        <Cell
          before={"🧵"}
          description="Которые создадут нужное настроение"
          multiline
        >
          Ткани и текстуры
        </Cell>
        <Cell
          before={"👠"}
          description="Подходящую под образ"
          multiline
        >
          Обувь
        </Cell>
        <Cell
          before={"💎"}
          description="Для завершения образа стильными деталями"
          multiline
        >
          Аксессуары
        </Cell>
      </div>
      <div className="w-full flex flex-row justify-between gap-4">
        <Button size="l" mode="filled" stretched onClick={() => onCtaClick()}>
          Как это работает?
        </Button>
      </div>
    </div>
  );
};
