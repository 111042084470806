import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { NewYearLookProvider } from "@/contexts/NewYearLookContext";
import { useBackendQuery } from "@/hooks/useBackendQuery";

import { Landing } from "./pages/Landing";
import { Path } from "./pages/Path";
import { HowItWorks } from "./pages/HowItWorks";
import { Payment } from "./pages/Payment";
import { AwaitingPaymentConfirmation } from "./pages/AwaitingPaymentConfirmation";
import { PhotoInstruction } from "./pages/PhotoInstruction";
import { PhotoSelection } from "./pages/Photo/PhotoSelection";
import { OutfitSelection } from "./pages/OutfitSelection";
import { Success } from "./pages/Success";
import { Upsell } from "./pages/Upsell";

export const newYearLookRoutes = [
  { path: "landing", Component: Landing },
  { path: "path", Component: Path },
  { path: "how-it-works", Component: HowItWorks },
  { path: "payment", Component: Payment },
  { path: "awaiting-payment-confirmation", Component: AwaitingPaymentConfirmation },
  { path: "photo-instruction", Component: PhotoInstruction },
  { path: "photo", Component: PhotoSelection },
  { path: "outfit-selection", Component: OutfitSelection },
  { path: "success", Component: Success },
  { path: "upsell", Component: Upsell },
];

export const NewYearLook: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { data, isFetching } = useBackendQuery<{ state: string }>({
    queryKey: ["newYearLookState"],
    url: `/api/v1/new-year-look?nocache=${Date.now()}`,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
    gcTime: 0,
  });

  React.useEffect(() => {
    if (isFetching || !data) return;
    const { state } = data;
    console.log({ pathname: location.pathname, state });
    if (location.pathname === "/new-year-look") {
      if (state === "landing") {
        navigate("/new-year-look/landing", { replace: true });
      }

      if (state === "photoUpload") {
        navigate("/new-year-look/photo-instruction", { replace: true });
      }

      if (state === "success") {
        navigate("/new-year-look/success", { replace: true });
      }

      if (state === "outfitSelection") {
        navigate("/new-year-look/outfit-selection", { replace: true });
      }
    }
  }, [location.pathname, isFetching, data]);

  return (
    <NewYearLookProvider>
      <Outlet />
    </NewYearLookProvider>
  );
};
