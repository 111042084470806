import React, { useState, useEffect } from 'react';

type ComponentRotatorProps = {
  components: React.ReactNode[];
  forceActiveComponent?: number | null;
  cycle?: boolean;
  interval?: number;
  transition?: number;
  containerStyles?: React.CSSProperties;
};

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const ComponentRotator: React.FC<ComponentRotatorProps> = ({
  components,
  forceActiveComponent = null,
  cycle = true,
  interval = 5000,
  transition = 500,
}) => {
  const [active, setActive] = useState(
    forceActiveComponent !== null ? forceActiveComponent : 0
  );
  const [firstTransitionDone, setFirstTransitionDone] = useState(false);

  useEffect(() => {
    if (forceActiveComponent !== null) return;
    if (!cycle) return;

    const timeout = setTimeout(() => {
      const startComponentTransition = async () => {
        if (firstTransitionDone) await wait(transition);
        setActive((prevActive) =>
          prevActive === components.length - 1 ? 0 : prevActive + 1
        );
        setFirstTransitionDone(true);
      };

      if (cycle) {
        startComponentTransition();
      }
    }, interval);

    return () => clearTimeout(timeout);
  }, [active, firstTransitionDone, cycle, forceActiveComponent, components.length, interval, transition]);

  useEffect(() => {
    if (forceActiveComponent !== null) {
      setActive(forceActiveComponent);
    }
  }, [forceActiveComponent]);

  return (
    <div className="relative">
      {components.map((component, index) => (
        <div
          key={index}
          className={`absolute inset-0 transition-opacity`}
          style={{
            opacity: active === index ? 1 : 0,
            transition: `opacity ${transition}ms`,
          }}
        >
          {component}
        </div>
      ))}
    </div>
  );
};
