import { initPopup, initUtils } from "@telegram-apps/sdk-react";
import { AxiosError } from "axios";
import { BackendError } from "@/hooks/useBackendMutation";

const errorHandler = (error: AxiosError<BackendError>) => {
  const popup = initPopup();
  const utils = initUtils();

  const { correlationId, message } = error.response?.data || {};
  popup.open({
    title: `${message}`,
    message: ''
      + `${correlationId}\n\n`
      + `Помогите решить проблему - сделайте скриншот и отправьте его разработчику`,
    buttons: [
      { id: "tochat", type: "default", text: "Перейти в чат" },
      { id: "ignore", type: "default", text: "Разбирайтесь без меня..." },
    ],
  }).then((buttonId) => {
    if (buttonId === "tochat") {
      utils.openTelegramLink("https://t.me/alpotapov");
    }
  });
};

export default errorHandler;