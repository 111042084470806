import React from "react";
import { Headline, Button } from "@telegram-apps/telegram-ui";
import { useNavigate } from "react-router-dom";

import { useTracking } from "@/services/tracking";
import { useScrollToTop } from "@/hooks/useScrollToTop";

import exampleImage from "../assets/example.png";
import processed1 from "../assets/processed1.png";
import processed2 from "../assets/processed2.png";
import processed3 from "../assets/processed3.png";
import processed4 from "../assets/processed4.png";
import processed5 from "../assets/processed5.png";
import processed6 from "../assets/processed6.png";
import processed7 from "../assets/processed7.png";
import processed8 from "../assets/processed8.png";
import processed9 from "../assets/processed9.png";

import { ComponentRotator } from "../components/ComponentRotator";

export const HowItWorks: React.FC = () => {
  const { track } = useTracking();
  const navigate = useNavigate();
  useScrollToTop();

  const onCtaClick = () => {
    track("", { group: "new-year-look" });
    navigate("/new-year-look/payment");
  };

  const NotProcessed = () => (
    <div className="flex-1 flex flex-col gap-4">
      <Headline weight="2">1️⃣ Загружаешь свою фотографию</Headline>
      <img src={exampleImage} alt="example" className="w-full" />
    </div>
  );

  const Processed = () => (
    <div className="flex-1 flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <Headline weight="2">2️⃣ Видишь себя в новых образах</Headline>
      </div>
      <div className="flex-1 flex flex-col">
        <div className="grid grid-cols-3 gap-4">
          <img src={processed1} alt="example" />
          <img src={processed2} alt="example" />
          <img src={processed3} alt="example" />
          <img src={processed4} alt="example" />
          <img src={processed5} alt="example" />
          <img src={processed6} alt="example" />
          <img src={processed7} alt="example" />
          <img src={processed8} alt="example" />
          <img src={processed9} alt="example" />
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen w-full flex flex-col justify-between gap-4 pt-2 pb-6 px-4">
      <ComponentRotator
        components={[<NotProcessed key={0} />, <Processed key={1} />]}
        interval={1000}
        transition={500}
      />
      <div className="w-full flex flex-row justify-between gap-4">
        <Button size="l" mode="filled" stretched onClick={() => onCtaClick()}>
          Готова начать?
        </Button>
      </div>
    </div>
  );
};
