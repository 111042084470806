import { useTWAEvent } from '@tonsolutions/telemetree-react';

export const useTracking = () => {
  const eventBuilder = useTWAEvent();

  const track = (eventName: string, data: Record<string, string>) => {
    // console.log("track", eventName, data);
    eventBuilder.track(eventName, data);
  };

  return { track };
};
