import React from "react";
import Cropper, { Area, Point } from "react-easy-crop";
import { Button } from "@telegram-apps/telegram-ui";

import { getCroppedImg } from "./croppingUtil";

import { useNewYearLookContext } from "@/contexts/NewYearLookContext";
import { ModalContext } from "@/contexts/ModalContextProvider";
import { PhotoEditingTipModal } from "./PhotoEditingTipModal";

const readFile = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });

interface PropTypes {
  droppedFile: File | null;
  onDone: (croppedImage: File) => void;
  onCancel: () => void;
  isPending: boolean;
}

export const PhotoCropper = ({
  droppedFile,
  onDone,
  onCancel,
  isPending,
}: PropTypes) => {
  const { hasSeenPhotoEditingTip, setHasSeenPhotoEditingTip } =
    useNewYearLookContext();
  const { setIsModalOpen, setModalType, addToModalLibrary } =
    React.useContext(ModalContext);

  React.useEffect(() => {
    console.log("addToModalLibrary", addToModalLibrary);
    addToModalLibrary({
      photoEditingTip: (
        <PhotoEditingTipModal onCloseModal={onClosePhotoEditingTip} />
      ),
    });
  }, []);

  const onOpenPhotoEditingTip = () => {
    setModalType("photoEditingTip");
    setIsModalOpen(true);
  };

  React.useEffect(() => {
    console.log("hasSeenPhotoEditingTip", hasSeenPhotoEditingTip);
    if (!hasSeenPhotoEditingTip) {
      onOpenPhotoEditingTip();
    }
  }, [hasSeenPhotoEditingTip]);

  const onClosePhotoEditingTip = () => {
    setHasSeenPhotoEditingTip(true);
    setIsModalOpen(false);
  };

  const [imageDimensions, setImageDimensions] = React.useState<{
    width: number;
    height: number;
  } | null>(null);
  const [imageSrc, setImageSrc] = React.useState<string | null>(null);
  const [crop, setCrop] = React.useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<Area | null>(
    null
  );
  const [croppedImage, setCroppedImage] = React.useState<string | null>(null);
  const [croppedFile, setCroppedFile] = React.useState<Blob | null>(null);

  const onCropComplete = React.useCallback(
    (_: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  React.useEffect(() => {
    if (droppedFile) {
      readFile(droppedFile).then((imageDataUrl) => {
        const img = new Image();
        img.src = imageDataUrl as string;
        img.onload = () => {
          setImageDimensions({ width: img.width, height: img.height });
          setImageSrc(img.src);
        };
      });
    }
  }, [droppedFile]);

  const onCrop = React.useCallback(async () => {
    if (!imageSrc || !croppedAreaPixels) {
      return;
    }

    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        768,
        1024
      );
      const { url, blob } = croppedImage as { url: string; blob: Blob };
      setCroppedImage(url);
      setCroppedFile(blob);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, imageSrc]);

  const onConfirm = () => {
    if (!croppedFile) {
      return;
    }

    const file = new File([croppedFile], "croppedImage.jpeg", {
      type: "image/jpeg",
    });
    onDone(file);
  };

  const { width, height } = imageDimensions || { width: 0, height: 0 };

  return (
    <div className="px-4">
      {imageSrc && !croppedImage && (
        <div className="flex flex-col gap-4 pb-4 relative">
          <div className="aspect-[3/4] relative">
            <Cropper
              image={imageSrc}
              crop={crop}
              objectFit={width > height ? "contain" : "horizontal-cover"}
              zoom={zoom}
              aspect={3 / 4}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
          <div className="flex flex-col gap-4">
            <Button onClick={onCrop}>Обрезать</Button>
            <div className="flex flex-row gap-2">
              <Button mode="plain" onClick={onOpenPhotoEditingTip}>
                Как обрезать?
              </Button>
              <Button mode="plain" onClick={onCancel}>
                Выбрать другое фото
              </Button>
            </div>
          </div>
        </div>
      )}
      {croppedImage && (
        <div className="flex flex-col gap-4 pb-4">
          <div className="w-full aspect-[3/4] flex flex-col items-center">
            <img src={croppedImage} alt="Cropped" className="aspect-[3/4]" />
          </div>
          <div className="flex flex-col gap-4">
            <Button onClick={onConfirm} loading={isPending}>
              Готово
            </Button>
            <Button
              mode="plain"
              onClick={() => setCroppedImage(null)}
              disabled={isPending}
            >
              Вернуться к редактированию
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
