/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {
  Headline,
  Subheadline,
  Button,
  Checkbox,
  Cell,
} from "@telegram-apps/telegram-ui";
import { useBackButton } from "@telegram-apps/sdk-react";
import { useNavigate } from "react-router-dom";

import { useTracking } from "@/services/tracking";
import { useScrollToTop } from "@/hooks/useScrollToTop";

import ReactPlayer from "react-player/file";

export const PhotoInstruction: React.FC = () => {
  const backButton = useBackButton();
  const { track } = useTracking();
  const navigate = useNavigate();

  useScrollToTop();

  const [areRequirementsUnderstood, setAreRequirementsUnderstood] =
    React.useState(false);

  React.useEffect(() => {
    backButton.hide();
  }, []);

  const onCtaClick = () => {
    track("", { group: "new-year-look" });
    navigate("/new-year-look/photo");
  };

  return (
    <div className="min-h-screen flex flex-col justify-between gap-6 pt-2 pb-6 px-4">
      <div className="flex flex-col gap-2">
        <Headline weight="2">Сделай свою фотографию</Headline>
        <Subheadline>
          Чем лучше фото, тем лучше результат. Посмотри видео, чтобы узнать, как
          сделать подходящую для ИИ фотографию.
        </Subheadline>
      </div>
      <div className="flex-1 flex justify-center items-center relative">
        <ReactPlayer
          height={350}
          width={194}
          // light
          url={
            "https://storage.yandexcloud.net/juliesalmina.sprintap.ru/photoInstruction.mp4"
          }
          muted
          controls
          playing
          previewTabIndex={10}
        />
      </div>
      <div className="w-full flex flex-col gap-4">
        <Cell
          Component="label"
          before={
            <Checkbox
              name="checkbox"
              checked={areRequirementsUnderstood}
              onChange={() =>
                setAreRequirementsUnderstood(!areRequirementsUnderstood)
              }
              value="1"
            />
          }
          description="Я понимаю, что качество обработки зависит от загруженной фотографии."
          multiline
        >
          Я посмотрела видео-инструкцию
        </Cell>
        <Button
          size="l"
          mode="filled"
          stretched
          disabled={!areRequirementsUnderstood}
          onClick={() => onCtaClick()}
        >
          Загрузить фото
        </Button>
      </div>
    </div>
  );
};
