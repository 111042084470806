import React from "react";

import { useLocalPhoto } from "@/hooks/useLocalPhoto";

export const NewYearLookContext = React.createContext<{
  handlePhotoSelected: (photo: File, onDone: () => void) => void;
  selectedPhotoUrl: string | null;
  selectedPhotoFile: File | null;
  hasSeenPhotoEditingTip: boolean;
  setHasSeenPhotoEditingTip: (hasSeen: boolean) => void;
}>({
  handlePhotoSelected: () => {},
  selectedPhotoUrl: null,
  selectedPhotoFile: null,
  hasSeenPhotoEditingTip: false,
  setHasSeenPhotoEditingTip: () => {},
});

interface PropTypes {
  children: React.ReactNode;
}

export const NewYearLookProvider: React.FC<PropTypes> = ({ children }) => {
  const [selectedPhotoUrl, setSelectedPhotoUrl] = React.useState<string | null>(
    null
  );
  const [selectedPhotoFile, setSelectedPhotoFile] = React.useState<File | null>(
    null
  );
  const [hasSeenPhotoEditingTip, setHasSeenPhotoEditingTip] =
    React.useState(false);

  const { handlePhotoSelected } = useLocalPhoto({
    setSelectedPhotoUrl,
    setSelectedPhotoFile,
    storageKey: "newYearLookPhoto",
  });

  return (
    <NewYearLookContext.Provider
      value={{
        handlePhotoSelected,
        selectedPhotoFile,
        selectedPhotoUrl,
        hasSeenPhotoEditingTip,
        setHasSeenPhotoEditingTip,
      }}
    >
      {children}
    </NewYearLookContext.Provider>
  );
};

export const useNewYearLookContext = () => {
  const context = React.useContext(NewYearLookContext);
  if (!context) {
    throw new Error(
      "useNewYearLookContext must be used within an NewYearLookProvider"
    );
  }
  return context;
};
