import React, { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { useNewYearLookContext } from "@/contexts/NewYearLookContext";

import { Dropzone } from "./components/Dropzone";
import { PhotoCropper } from "./components/PhotoCropper";
import { useBackendMutation } from "@/hooks/useBackendMutation";

import errorHandler from "@/util/errorHandler";

import { useTracking } from "@/services/tracking";

export const PhotoSelection: FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { track } = useTracking();

  const uploadPhoto = useBackendMutation({
    method: "PUT",
    url: "/api/v1/new-year-look",
    mutationKey: ["uploadPhoto"],
    contentType: "multipart/form-data",
    onSuccess: () => {
      track("Фото загружено", { group: "new-year-look" });
      queryClient.invalidateQueries({ queryKey: ["newYearLookState"] });
      navigate("/new-year-look", { replace: true });
    },
    onError: errorHandler,
  });

  const { handlePhotoSelected, selectedPhotoFile } = useNewYearLookContext();

  const [droppedFile, setDroppedFile] = React.useState<File | null>(
    selectedPhotoFile
  );

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      track("Выбрал фото", { group: "new-year-look" });
      setDroppedFile(acceptedFiles[0]);
    }
  }, []);

  const onResetDroppedFile = () => {
    setDroppedFile(null);
  };

  const onCropDone = (croppedImage: File) => {
    handlePhotoSelected(croppedImage, () => {
      track("Клик на Готово", { group: "new-year-look" });
      const formData = new FormData();
      formData.append("photo", croppedImage);

      uploadPhoto.mutate(formData);
    });
  };

  if (!droppedFile) {
    return <Dropzone onDrop={onDrop} />;
  }

  return (
    <PhotoCropper
      droppedFile={droppedFile}
      onDone={onCropDone}
      onCancel={onResetDroppedFile}
      isPending={uploadPhoto.isPending}
    />
  );
};
